import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "./services.css"
import ServiceCard from "../components/ServiceCard/ServiceCard"

export const ServicesPageTemplate = ({
  header,
  special_offer,
  service_list,
}) => {
  return (
    <div className="services-page-container">
      <h2 className="page-header">{header}</h2>
      {special_offer && (
        <p className="field-special services-disclaimer">{special_offer}</p>
      )}
      <div className="service-cards-container">
        {service_list.map(service => (
          <ServiceCard {...service} key={service.service_name} />
        ))}
      </div>
    </div>
  )
}

const ServicesPage = ({ data, location }) => {
  const {
    header,
    special_offer,
    service_list,
  } = data.markdownRemark.frontmatter

  return (
    <Layout location={location} seoTitle="Usługi">
      <ServicesPageTemplate
        header={header}
        special_offer={special_offer}
        service_list={service_list}
      />
    </Layout>
  )
}

export default ServicesPage

export const ServicesPageQuery = graphql`
  query ServicesPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        header
        special_offer
        service_list {
          service_name
          short_description
          description
          old_price
          actual_price
        }
      }
    }
  }
`
