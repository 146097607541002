import React from "react"
import "./ServiceCard.css"

const ServiceCard = ({
  service_name,
  short_description,
  description,
  old_price,
  actual_price,
}) => {
  return (
    <div className="service-card">
      <h3 className="service_name">{service_name}</h3>
      <p className="service_short_description">{short_description}</p>
      <p className="service_description">{description}</p>
      <div
        className="prices"
        style={{
          flexDirection: `${
            old_price?.length > 10 || actual_price?.length > 10
              ? "column"
              : "row"
          }`,
        }}
      >
        {old_price && <span className="old_price">{old_price}</span>}
        <span className="actual_price">{actual_price}</span>
      </div>
    </div>
  )
}

export default ServiceCard
